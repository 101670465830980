@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}

.waves {
  animation: gradient 15s ease infinite;
}

.wave {
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  animation: wave 18s linear reverse infinite;
}

.wave:nth-of-type(3) {
  animation: wave 20s -1s reverse infinite;
}

@layer base {
  html {
    font-family: "Inter", sans-serif;
  }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

iframe {
  padding: 0px !important;
  margin: 0px !important;
}

svg {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  appearance: none;
}

.form-checkbox:checked + .form-checkbox-label .tick-box {
  border: 1px solid #1570ef;
  background-color: #1570ef;
}

input:checked + div svg {
  @apply block;
}

.card-table::-webkit-scrollbar {
  width: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill::first-line,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill::first-line,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill::first-line {
  border: 1px solid #d0d5dd;
  -webkit-text-fill-color: #101828;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  border: 1px solid #7cd4fd;
  transition: background-color 5000s ease-in-out 0s;
}

.card-table::-webkit-scrollbar-thumb {
  background-color: #c5c6c9;
  border-radius: 4px;
  border-left: solid 2px white;
  border-right: solid 2px white;
}

.slider::-webkit-scrollbar {
  height: 8px;
}

.slider::-webkit-scrollbar-thumb {
  background-color: #c5c6c9;
  border-radius: 8px;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.json-scroller::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

.json-scroller::-webkit-scrollbar-thumb {
  background-color: #909194;
  border-radius: 8px;
}

.scroll::-webkit-scrollbar-corner {
  border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.main-grid {
  display: grid;
  column-gap: 1rem;
  padding-bottom: 1rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.inner-grid {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-flow-dense {
  grid-auto-flow: dense;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 12px;
  border-color: transparent transparent transparent #f0f9ff;
}
